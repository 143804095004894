


import { Button } from "@fluentui/react-components";
import * as AC from "adaptivecards";

export interface ActionButtonProps {
  action: AC.Action;
  className?: string;
  iconPlacement?: AC.ActionIconPlacement;
  iconSize?: number;
}

export const ActionButton = (props: ActionButtonProps) => {
  console.log(`Setting up ActionButton ${props.action.title}`);

  if (props.action.iconUrl) {
    return (
      <Button className={props.className} title={props.action.title} onClick={(e) => props.action.execute()} size="large">
        {props.action.title}
        {/* <div
            style={{
              display: "flex",
              flexDirection:
                props.iconPlacement === AC.ActionIconPlacement.LeftOfTitle
                  ? "row"
                  : "column",
              justifyContent: "center",
            }}
          >
            <img
              src={props.action.iconUrl}
              style={{
                alignSelf: "center",
                width: props.iconSize,
                height: props.iconSize,
                flex: "0 0 auto",
              }}
            />
            <span style={{ alignSelf: "center" }}>{props.action.title}</span>
          </div> */}
      </Button>
    );
  } else if (props.action.style === AC.ActionStyle.Positive) {
    console.log("Setting up Positive");
    return (
      <Button className={props.className} title={props.action.title} onClick={(e) => props.action.execute()} size="large">
        {props.action.title}
      </Button>
    );
  } else {
    console.log("Setting up Default");
    return (
      <Button className={props.className} title={props.action.title} onClick={(e) => props.action.execute()} size="large">{props.action.title}</Button>
    );
  }
};