import { useMsal } from "@azure/msal-react";
import { AiChat } from '@nlux/react';
import { useEffect, useMemo, useState } from "react";
import { AIChatApi } from "../../ApiClients/AIChatApi";
import { myCustomPromiseAdapter } from "../../Components/OpenAIAdaptor";
import { useParams } from "react-router-dom";
import { botStyle, user } from "./personas";
import '@nlux/themes/nova.css';
import './custom-nova-theme.css';

export const AIChatMain = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const msal = useMsal();
    useEffect(() => {
        const createThread = async () => {
            try
            {
                const api = new AIChatApi(msal);
                var thread = await api.createThread();
                myCustomPromiseAdapter.threadId = thread.id;
                console.log(`threadId id is ${thread.id}`)
            } catch (e) {
                setErrorMessage(`${e}`);
            }
        }
        console.log("Creating chat thread.")
        createThread();
    }, [msal])

    const { id } = useParams();
    console.log(`assistant id is ${id}`)
    myCustomPromiseAdapter.assisantId = id;
    myCustomPromiseAdapter.msal = msal;
    myCustomPromiseAdapter.api = new AIChatApi(msal);
    const adapter = useMemo(() => myCustomPromiseAdapter, []);

    if (!id) {
        return (<div>Assistant ID is missing</div>);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <AiChat
                className="custom-ai-chat-comp"
                adapter={adapter}
                personaOptions={{
                    bot: {
                        name: 'iBot',
                        picture: <span style={botStyle}>🤖</span>,
                        tagline: 'The Frontline App AI Assistant'
                    },
                    user
                }}
                layoutOptions={{
                    maxWidth: 600
                }}
            />
            <div>{errorMessage}</div>
        </div>
    );
}