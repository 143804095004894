
import * as AC from "adaptivecards";
import * as Shared from "./Shared";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import moment from "moment";

export class InputDateFluentUI extends AC.DateInput {
  private handleSelectDate = (date: Date | null | undefined) => {
    console.log(`Date ${date}`)
    this._value = moment(date).format('YYYY-MM-DD');
    console.log(`Date ${this._value}`)
    // this._value = date
    //   .toLocaleString(window.navigator.language, {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //   })
    //   .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
  };

  private _value: any | undefined;;
  public get value(): any | undefined {
    return this._value;
  }

  public isSet(): boolean {
    return this._value ? true : false;
  }

  protected internalRender(): HTMLElement {
    if (this.defaultValue) {
      this._value = this.defaultValue;
    }

    const element = Shared.sharedInternalRender(this.renderReact);
    element.style.width = "100%";
    return element;
  }

  private getDate = (
    dateString: string,
    errors?: AC.IValidationEvent[]
  ): Date | undefined => {
    try {
      return new Date(dateString + "T00:00:00");
    } catch (error) {
      Shared.raiseParseError(
        {
          phase: AC.ValidationPhase.Parse,
          event: AC.ValidationEvent.InvalidPropertyValue,
          message: `${error}`,
        },
        errors ?? []
      );
      return undefined;
    }
  };

  // NOT WORKING
  private onFormatDate = (date?: Date): string => {
    return !date
      ? ""
      : date.toLocaleString(window.navigator.language, { weekday: "short" }) +
      " " +
      (date.getMonth() + 1) +
      "/" +
      date.getDay() +
      "/" +
      date.getFullYear();


  };

  private onParseDateFromString = (val: string): Date => {
    const date = this.value || new Date();
    // const values = (val || '').trim().split('/');
    // const day = val.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate();
    // const month = val.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
    // let year = val.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
    // if (year < 100) {
    //   year += date.getFullYear() - (date.getFullYear() % 100);
    // }

    console.log(this.defaultValue);

    return new Date();
    //return new Date(year, month, day);
  };

  private renderReact = (): JSX.Element => {
    return (
      <DatePicker
        id={this.id} size="large"
        style={{ width: '100%' }}
        //onChange={(e, d) => this.handleSelectDate(d.value)}
        onSelectDate={this.handleSelectDate}
        value={this.defaultValue ? this.getDate(this.defaultValue) : undefined}
        //label={this.label}
        placeholder={this.placeholder}
        //formatDate={this.onFormatDate}
        //allowTextInput={true}
        parseDateFromString={this.onParseDateFromString}
        minDate={this.min ? this.getDate(this.min) : undefined}
        maxDate={this.max ? this.getDate(this.max) : undefined}
      />
    );
  };
}