import { encode } from "base64-arraybuffer";
import moment from 'moment';
import { ReferenceDataApi } from "../ApiClients/ReferenceDataApi";
import { UserApi } from "../ApiClients/UserApi";
import { Verification, ListVerificationRequest, ReviewRequest } from "../Models/Users";
import { Template } from "adaptivecards-templating";
import { forEachChild } from "typescript";

export class VerificationListViewModel {
    private userApi: UserApi;
    private referenceApi: ReferenceDataApi;
    private continuationToken?: string | null;
    private cardName: string;

    constructor(userApi: UserApi, referenceApi: ReferenceDataApi, statusSet: string[], cardName: string) {
        this.cardName = cardName;
        this.userApi = userApi;
        this.referenceApi = referenceApi;
        this.statusSet = statusSet;
        this.continuationToken = null;
        this.template = new Template({});
    }

    list: Verification[] = [];
    statusSet: string[];
    hasMore: boolean = true;
    template: Template;
    refreshList?(): void;

    initViewModel = async () => {
        const result = await this.referenceApi.getCard(this.cardName);
        this.template = new Template(result.card);
        console.log(`VerificationCard card loaded ${this.cardName}`);
        await this.loadMore();
    }

    loadImages = async (data: Verification) => {
        if (data.picture && !data.pictureImage) {
            var temp = sessionStorage.getItem(data.picture);
            if (!temp) {
                const byteData = await this.userApi.userFile(data.picture);
                temp = `data:image/jpg;base64,${encode(byteData)}`;
                sessionStorage.setItem(data.picture, temp);
            }
            data.pictureImage = temp;
            console.log("finish loading image1");
        }
        if (data.membershipCard && !data.membershipCardImage) {
            var temp = sessionStorage.getItem(data.membershipCard);
            if (!temp) {
                const byteData = await this.userApi.userFile(data.membershipCard, 100);
                temp = `data:image/jpg;base64,${encode(byteData)}`;
                sessionStorage.setItem(data.membershipCard, temp);
            }
            data.membershipCardImage = temp;
            console.log("finish loading image2");
        }
        data.requested = moment(data.createdOn).format('Do MMM YYYY, HH:mm');
        data.membershipCardLink = "https://www.youtube.com";
    }

    loadChanges = async () => {
        console.log(`loading changes called`);
        var modified = new Date(2024,1,1);
        if (this.list.length > 0) {
            modified = this.list[0].modifiedOn;
        }
        //console.log(`loading changes form ${modified}`);
        const request: ListVerificationRequest = { statusSet: this.statusSet, modifiedOn: modified }
        const result = await this.userApi.getVerificationChanges(request);
        //console.log(`result of changes form ${result.result.length}`);

        if (result.result.length > 0) {
            result.result.forEach(item => {
                this.list = this.list.filter(q => q.id != item.id);
                this.list.unshift(item);
            });
            this.list = [...this.list];
            if (this.refreshList) {
                this.refreshList();
            }
        }

    }

    loadMore = async () => {
        console.log(`loading more called`);
        const request: ListVerificationRequest = { continuationToken: this.continuationToken, statusSet: this.statusSet }
        const result = await this.userApi.getVerificationList(request);

        console.log(`Total count ${result.result.length}`);
        this.continuationToken = result.continuationToken;
        this.list = [...result.result];
        if (result.continuationToken) {
            this.hasMore = true;
        }
        else {
            this.hasMore = false;
        }
    }

    submitReview = async (request: ReviewRequest) => {
        const result = await this.userApi.submitVerificationReview(request);
        if (result.status !== 'Pending') {
            const newList = this.list.filter((item) => item.id !== result.id);
            this.list = newList;
            debugger;
        }
    }
}