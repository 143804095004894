import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
import './index.css';
import { App } from './App';
import { loginRequest, msalConfig } from './authConfig';
import { Button, FluentProvider, teamsDarkTheme, teamsLightTheme, useThemeClassName } from '@fluentui/react-components';

export const msalInstance = new PublicClientApplication(msalConfig);


msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    console.log(`${event.eventType} called`);

    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      console.log('Set active account');
    }
  });
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  function ApplyToBody() {
    const classes = useThemeClassName();
    React.useEffect(() => {
      const classList = classes.split(" ");
      document.body.classList.add(...classList);
      return () => document.body.classList.remove(...classList);
    }, [classes]);
    return null;
  }

  root.render(
    <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <App />
          {/* <ApplyToBody /> */}
        </MsalProvider>
    </BrowserRouter>
  );
});

