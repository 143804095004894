import * as AC from "adaptivecards";
import * as Shared from "./Shared";
import ReactDOM from "react-dom";
import { ActionButton } from "./ActionButton";

export const createActionDiv = (
    action: AC.Action,
    baseCssClass: string | undefined,
    iconPlacement: AC.ActionIconPlacement | undefined,
    iconSize: number | undefined): HTMLDivElement => {
    const div = Shared.getDiv();
    ReactDOM.render(
        <ActionButton
            action={action}
            className={baseCssClass}
            iconPlacement={iconPlacement}
            iconSize={iconSize}></ActionButton>, div);
    return div;
};

export class OpenUrlActionFluentUI extends AC.OpenUrlAction {

    private internalRenderedElement: any;

    get renderedElement(): HTMLElement {
        return this.internalRenderedElement;
    }

    public render(baseCssClass?: string) {
        let actionsConfig = this.parent?.hostConfig.actions;
        const div = createActionDiv(this, baseCssClass, actionsConfig?.iconPlacement, actionsConfig?.iconSize);
        this.internalRenderedElement = div;
    }
}

export class SubmitActionFluentUI extends AC.SubmitAction {

    private internalRenderedElement: HTMLElement | undefined;

    get renderedElement(): HTMLElement | undefined {
        return this.internalRenderedElement;
    }

    public render(baseCssClass?: string): void {
        let actionsConfig = this.parent?.hostConfig.actions;
        const div = createActionDiv(this, baseCssClass, actionsConfig?.iconPlacement, actionsConfig?.iconSize);
        this.internalRenderedElement = div;
    }
}

export class ShowCardActionFluentUI extends AC.ShowCardAction {

    private internalRenderedElement: HTMLElement | undefined;

    get renderedElement(): HTMLElement | undefined {
        return this.internalRenderedElement;
    }

    public render(baseCssClass?: string): void {
        let actionsConfig = this.parent?.hostConfig.actions;
        const div = createActionDiv(this, baseCssClass, actionsConfig?.iconPlacement, actionsConfig?.iconSize);
        this.internalRenderedElement = div;
    }
}

export class ToggleVisibilityActionFluentUI extends AC.ToggleVisibilityAction {

    private internalRenderedElement: HTMLElement | undefined;

    get renderedElement(): HTMLElement | undefined {
        return this.internalRenderedElement;
    }

    public render(baseCssClass?: string): void {
        const div = Shared.getDiv();
        ReactDOM.render(<ActionButton action={this} className={baseCssClass} />, div);
        this.internalRenderedElement = div;
    }
}