
export const botStyle = {
    background: 'linear-gradient(yellow, orange)',
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  
  export const user = {
    name: 'User',
    picture: 'https://thefrontline.app/images/police-officer.png'
  };