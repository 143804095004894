import { SelectTabData, SelectTabEvent, Tab, TabList, TabValue } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { VerificaitonList } from "./VerificaitonList";
import { VerificationListViewModel } from "../../ViewModels/VerificationViewModel";
import { useMsal } from "@azure/msal-react";
import { UserApi } from "../../ApiClients/UserApi";
import { ReferenceDataApi } from "../../ApiClients/ReferenceDataApi";

export const MainList = () => {
    const [pendingVM, setPendingVM] = useState<VerificationListViewModel>();
    const [completedVM, setCompletedVM] = useState<VerificationListViewModel>();
    const [selectedValue, setSelectedValue] = useState<TabValue>("pending");
    const msal = useMsal();

    useEffect(() => {
        console.log('Setting ViewModels');
        const userApi = new UserApi(msal);
        const referenceDataApi = new ReferenceDataApi(msal);
        const pending = new VerificationListViewModel(userApi, referenceDataApi, ['Pending'], 'PendingVerificationCard');
        pending.initViewModel().then(() => {
            console.log(`setPendingVM`);
            setPendingVM(pending);
        })
    }, [])

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
        if (data.value == 'completed') {
            if (!completedVM) {
                const userApi = new UserApi(msal);
                const referenceDataApi = new ReferenceDataApi(msal);
                const completed = new VerificationListViewModel(userApi, referenceDataApi, ['Verified', 'Declined'], 'CompletedVerificationCard');
                completed.initViewModel().then(() => {
                    console.log(`setCompletedVM`);
                    setCompletedVM(completed);
                })
            }
            completedVM?.loadChanges();
        }
        if (data.value == 'pending') {
            pendingVM?.loadChanges();
        }
    };

    return (<div>
        <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="Pending" value="pending">
                Pending
            </Tab>
            <Tab id="Completed" value="completed">
                Completed
            </Tab>
        </TabList>
        <div>
            <div style={{ display: selectedValue === 'pending' ? "block" : 'none' }}>{pendingVM && (<VerificaitonList viewModel={pendingVM} />)}</div>
            <div style={{ display: selectedValue === 'completed' ? "block" : 'none' }}>{completedVM && (<VerificaitonList viewModel={completedVM} />)}</div>
        </div>
    </div>)
}