import { IMsalContext } from "@azure/msal-react";
import { apiConfig, loginRequest } from "../authConfig"
import { Assistant, ChatThread, Message, MessageInput, ThreadRun, ThreadRunInput } from "../Models/ChatModels";
import { BaseSerializationContext } from "adaptivecards";
import { BaseApi } from "./BaseApi";

export class AIChatApi extends BaseApi {

    constructor(msal: IMsalContext) {
        super(msal);
    }

    async askQuestion(threadId: string, assistantId: string, message: MessageInput) {
        var accessToken = await this.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/AskQuestion/${threadId}/${assistantId}`;
        let response = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(message)
        });
        const data: Message[] = await response.json();
        console.log(`Total ${data.length} answer return`);
        return data;
    }

    async checkStatus() {
        try {
            var accessToken = await this.getToken();
            var url = `${apiConfig.apiEndpoint}/api/OpenAI/CheckStatus`;
            let response = await fetch(url, {
                method: 'get',
                headers: new Headers({
                    'Authorization': `bearer ${accessToken}`
                })
            });
            if (response.status === 200) {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log(error);
        }
        return false;
    }

    async listAssistants() {
        var accessToken = await super.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/ListAssistants`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: Assistant[] = await response.json();
        return data;
    }

    async getAssistant(assistantId: string) {
        var accessToken = await this.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/GetAssistant/${assistantId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: Assistant = await response.json();
        return data;
    }

    async createThread() {
        var accessToken = await this.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/CreateThread`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        var json = await response.json();
        console.log(`createThread: ${JSON.stringify(json)}`);
        const data: ChatThread = json;
        return data;
    }

    async deleteThread(threadId: string) {
        var accessToken = await this.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/DeleteThread/${threadId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: ChatThread = await response.json();
        return data;
    }

    async sendMessage(threadId: string, message: MessageInput) {
        var accessToken = await this.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/SendMessage/${threadId}`;
        let response = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(message)
        });
        const data: ChatThread = await response.json();
        return data;
    }

    async createThreadRun(threadId: string, run: ThreadRunInput) {
        var accessToken = await this.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/CreateThreadRun/${threadId}`;
        let response = await fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(run)
        });
        const data: ThreadRun = await response.json();
        return data;
    }

    async getThreadRun(threadId: string, runId: string) {
        var accessToken = await this.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/GetThreadRun/${threadId}/${runId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: ThreadRun = await response.json();
        return data;
    }

    async listThreadMessages(threadId: string) {
        var accessToken = await this.getToken();
        var url = `${apiConfig.apiEndpoint}/api/OpenAI/ListThreadMessages/${threadId}`;
        let response = await fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        const data: Message[] = await response.json();
        return data;
    }
}