import { AdaptiveCard } from "adaptivecards";
import { Verification } from "../Models/Users";
import { Template } from "adaptivecards-templating";
import React from "react";


export const CardRenderer = (props: { cardSource: AdaptiveCard, data: Verification, template: Template, imageLoader?(data: Verification): Promise<void> }) => {
  const cardWrapperRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const renderCard = () => {
      console.log(`renderCard called ${props.data.id}`);
      if (!cardWrapperRef || !cardWrapperRef.current) return;
      const finalCard = props.template.expand({
        $root: props.data
      });
      props.cardSource.parse(finalCard);

      cardWrapperRef.current.innerHTML = "";
      props.cardSource.render(cardWrapperRef.current);
    }

    if (props.imageLoader) {
      props.imageLoader(props.data).then( () => {
        //console.log(`ImageData ${props.data.pictureImage}`);
        renderCard();
      })
    } else {
      renderCard();
    }

    //console.log(`Rendering card: ${props.form.id}`);
  }, [props.cardSource, cardWrapperRef, props.template]);
  return (<div ref={cardWrapperRef}></div>);
}