import { Route, Routes } from "react-router-dom";
import { HomePage } from "./HomePage";
import { AIChatMain } from "./Modules/AIChat/AIChatMain";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { MainList } from "./Modules/Verifications/MainList";
import { useEffect, useState } from "react";
import { Button, FluentProvider, teamsDarkTheme, teamsLightTheme, useThemeClassName } from '@fluentui/react-components';

export const App = () => {
  const { instance } = useMsal();
  const currentAuth = useIsAuthenticated() || window.sessionStorage.getItem('access_token');
  console.log(`Currrent is ${currentAuth}`);
  const [isAuth, setIsAuth] = useState(currentAuth);
  console.log(`isAuth is ${currentAuth}`);

  const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
  const hostDarkMode = sessionStorage.getItem("IsDarkTheme");
  const isDarkTheme = true;


  window.setTheme = (isDark: boolean) => {
    sessionStorage.setItem('IsDarkTheme', `${isDark}`);
    //setIsDarkTheme(true);
  }

  window.addToken = (token: string, expiry: string) => {
    window.sessionStorage.setItem('access_token', token)
    setIsAuth(true);
    return 'OK';
  }

  useEffect(() => {
    setIsAuth(currentAuth);
  });

  const loginClick = () => {
    instance.loginRedirect(loginRequest);
  }
  console.log(`Login status ${isAuth}`);

  function ApplyToBody() {
    const classes = useThemeClassName();
    useEffect(() => {
      const classList = classes.split(" ");
      document.body.classList.add(...classList);
      return () => document.body.classList.remove(...classList);
    }, [classes]);
    return null;
  }

  const ApplyDarkThemeCss = () => {
    return (<link rel="stylesheet" type="text/css" href='darktheme.css' />) 
  }

  return isAuth ? (
  <FluentProvider theme={isDarkTheme ? teamsDarkTheme : teamsLightTheme}>
    {isDarkTheme && (<ApplyDarkThemeCss />)}
    <Routes>
    <Route path="/" element={HomePage()} />
    <Route path="Verifications" element={<MainList />} />
    <Route path='AIChat'>
      <Route path=':id' element={<AIChatMain />} />
    </Route>
  </Routes>
    <ApplyToBody />
  </FluentProvider>)
    : <FluentProvider theme={isDarkTheme ? teamsDarkTheme : teamsLightTheme}>
      {isDarkTheme && (<ApplyDarkThemeCss />)}
      <Button onClick={(e) => loginClick()}>Login</Button>
      <ApplyToBody />
    </FluentProvider>

}