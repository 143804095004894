import * as AC from "adaptivecards";
import { InputTextFluentUI } from "./CardInputs/InputTextFluentUI";
import { InputDateFluentUI } from "./CardInputs/InputDateFluentUI";
import * as Actions from "././CardInputs/Actions";

export function useFluendUI() {
    AC.GlobalRegistry.elements.register("Input.Text", InputTextFluentUI);
    AC.GlobalRegistry.elements.register("Input.Date", InputDateFluentUI);

    // add actions extensions
    AC.GlobalRegistry.actions.register("Action.OpenUrl", Actions.OpenUrlActionFluentUI);
    AC.GlobalRegistry.actions.register("Action.Submit", Actions.SubmitActionFluentUI);
    AC.GlobalRegistry.actions.register("Action.ShowCard", Actions.ShowCardActionFluentUI);
    AC.GlobalRegistry.actions.register("Action.ToggleVisibility", Actions.ToggleVisibilityActionFluentUI);
    AC.GlobalRegistry.actions.register("Action.Execute", Actions.SubmitActionFluentUI);
}