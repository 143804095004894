import { BrowserCacheLocation, Configuration, PopupRequest } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_SignUpSignIn"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://internatpoliceassoc.b2clogin.com/tfp/IPA.TheFrontline.App/B2C_1_SignUpSignIn",
        }
    },
    authorityDomain: "internatpoliceassoc.b2clogin.com"
};

// Config object to be passed to Msal on creation
export const msalConfig : Configuration = {
    auth: {
        clientId: "f0a25040-6a73-49df-ac2c-23305fb64471",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin + '/',
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    },
    cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage
    }
};



// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [ "https://IPA.TheFrontline.App/765690f3-1047-4264-a555-6408914f8e44/Execute" ]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const apiConfig = {
    apiEndpoint: "https://ipa-web-api-dev.azurewebsites.net"
};

export const graphQLEndpoint = {
    apiEndpoint: "https://gql-00-rev.azurewebsites.net"
};