import * as AC from "adaptivecards";
import * as Shared from "./Shared";
import { Input, Text, Textarea } from "@fluentui/react-components";

export class InputTextFluentUI extends AC.TextInput {
    protected handleChange = (newValue?: string) => {
        this._value = newValue;
    }

    private _value: any | undefined;
    public get value(): any {
        return this._value;
    }

    public isSet(): any {
        return this._value ? true : false;
    }

    protected internalRender(): HTMLElement {
        if (this.defaultValue) {
            this._value = this.defaultValue;
        }

        const element = Shared.sharedInternalRender(this.renderReact);
        element.style.width = "100%";
        return element;
    }

    private renderReact = (): JSX.Element => {
        const inputStyle = 'text';
        if (this.isMultiline) {
            return (<Textarea
                id={this.id}
                size="large"
                style={{width:'100%'}}
                defaultValue={this.defaultValue}
                maxLength={this.maxLength ? this.maxLength : undefined}
                placeholder={this.placeholder}
                required={this.isRequired}
                title={this.label}
                //label={this.label}
                onChange={(e, d) => this.handleChange(d.value)}
                onKeyDown={this.handleKeyDown} />)
        }
        return (
            <Input
                id={this.id}
                size="large"
                style={{width:'100%'}}
                type={inputStyle}
                defaultValue={this.defaultValue}
                maxLength={this.maxLength ? this.maxLength : undefined}
                placeholder={this.placeholder}
                required={this.isRequired}
                title={this.label}
                //label={this.label}
                onChange={(e, d) => this.handleChange(d.value)}
                onKeyDown={this.handleKeyDown}
            />
        );
    }

    private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        // Enter pressed
        if (e.keyCode === 13 && this.inlineAction) {
            this.inlineAction.execute();
        }
    }
}