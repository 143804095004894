import { IMsalContext } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { AuthenticationResult } from "@azure/msal-browser";

export class BaseApi {
    private token?: AuthenticationResult;
    private msal: IMsalContext;

    constructor(msal: IMsalContext) {
        this.msal = msal;
    }
    async getToken() {
        const session_token = window.sessionStorage.getItem('access_token');
        console.log(`session token: ${session_token}`);
        if (session_token) {
            return session_token;
        }
        if (this.token && this.token.expiresOn) {
            if (this.token.expiresOn < new Date()) {
                return this.token.accessToken;
            }
        }

        if (this.token && this.token.expiresOn && this.token.expiresOn > new Date()) {
            return this.token.accessToken;
        }
        this.token = await this.msal.instance.acquireTokenSilent(loginRequest);
        return this.token.accessToken;
    }

    async callApi(url: string, method: string, payload?: any) {
        const accessToken = await this.getToken();
        let response = await fetch(url, {
            method: method,
            body: payload ? JSON.stringify(payload) : null,
            headers: new Headers({
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            })
        });
        return response;
    }
}