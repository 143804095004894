import { IMsalContext } from "@azure/msal-react";
import { apiConfig, loginRequest } from "../authConfig";
import { Card } from "../Models/ReferenceData";
import { Authentication } from "adaptivecards";
import { AuthenticationResult } from "@azure/msal-browser";
import { BaseApi } from "./BaseApi";

export class ReferenceDataApi extends BaseApi {

    constructor(msal: IMsalContext) {
        super(msal);
    }

    async getCard(id: string) {
        var url = `${apiConfig.apiEndpoint}/api/System/Card/${id}`;
        const response = await this.callApi(url, 'get');
        const data: Card = await response.json();
        return data;
    }
}