import { IMsalContext } from "@azure/msal-react";
import { apiConfig, loginRequest } from "../authConfig";
import { ListVerificationRequest, ListVerificationResult, ReviewRequest, Verification } from "../Models/Users";
import { AuthenticationResult } from "@azure/msal-browser";
import { BaseApi } from "./BaseApi";

export class UserApi extends BaseApi {
    constructor(msal: IMsalContext) {
        super(msal);
    }

    async userFile(fileId: string, size?: number) {
        var url = `${apiConfig.apiEndpoint}/api/Users/Picture?filename=${fileId}&size=${size ?? ''}`;
        const response = await this.callApi(url, 'get', null);
        return await response.arrayBuffer();
    }

    async getVerificationList(request: ListVerificationRequest) {
        const url = `${apiConfig.apiEndpoint}/api/Verifications/List`;
        const response = await this.callApi(url, 'post', request);
        const data: ListVerificationResult = await response.json();
        return data;
    }

    async getVerificationChanges(request: ListVerificationRequest) {
        const url = `${apiConfig.apiEndpoint}/api/Verifications/Changes`;
        const response = await this.callApi(url, 'post', request);
        const data: ListVerificationResult = await response.json();
        return data;
    }

    async submitVerificationReview(request: ReviewRequest) {
        const url = `${apiConfig.apiEndpoint}/api/Verification/Review`;
        const response = await this.callApi(url, 'post', request);
        const data: Verification = await response.json();
        return data;
    }
}