import React, { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReviewRequest, Verification } from '../../Models/Users';
import { Template } from 'adaptivecards-templating';
import { Action, AdaptiveCard, HostConfig, OpenUrlAction, SubmitAction } from 'adaptivecards';
import { CardRenderer } from '../../Components/CardRenderer';
import { VerificationListViewModel } from '../../ViewModels/VerificationViewModel';
import { isConstructorDeclaration } from 'typescript';
import { useFluendUI } from '../../Components/FluentUI';

export const VerificaitonList = (props: { viewModel: VerificationListViewModel }) => {
  const [canLoadMore, setCanLoadMore] = useState(props.viewModel.hasMore);
  const [items, setItems] = useState<Verification[]>(props.viewModel.list);

  props.viewModel.refreshList = () => {
    console.log('refreshList called');
    setItems(props.viewModel.list);
  }

  useFluendUI();

  const card = useMemo(() => {
    const tempCard = new AdaptiveCard();
    tempCard.onExecuteAction = (action: Action) => {
      if (action instanceof OpenUrlAction) {
        window.open(action.url, '_blank');
      } else if (action instanceof SubmitAction) {
        props.viewModel?.submitReview(action.data as ReviewRequest).then(() => {
          setItems(props.viewModel?.list ?? []);
        })
        console.log(JSON.stringify(action.data));
      }
    }
    const cardConfig = new HostConfig({
      fontFamily: "Segoe UIm Helvetica Neue, sans-serif",
    });
    tempCard.hostConfig = cardConfig;
    console.log(`Adaptive Card created`);
    return tempCard;
  }, [])

  const loadMore = () => {
    if (props.viewModel) {
      props.viewModel.loadMore().then(() => {
        setItems(props.viewModel?.list ?? []);
        setCanLoadMore(props.viewModel?.hasMore ?? false);
      });
    }
  }

  const loadImages = async (data: Verification) => {
    await props.viewModel?.loadImages(data);
  }

  const MemRenderCard = (props: { card: AdaptiveCard, data: Verification, template: Template }) => {
    const mem = useMemo(() => {
      return (<CardRenderer cardSource={props.card} data={props.data} template={props.template} imageLoader={loadImages} />)
    }, [props.data.modifiedOn])
    return mem;
  }

  const List = useMemo(() => {
    console.log(`rerendering list`)
    return (<InfiniteScroll dataLength={items.length} className="card-container"
      next={loadMore}
      hasMore={canLoadMore} // Replace with a condition based on your data source
      loader={<p>Loading...</p>} scrollThreshold={1}
      endMessage={<></>}>
      {items.map((form) => {
        return (<MemRenderCard key={form.id} card={card} data={form} template={props.viewModel?.template ?? new Template({})} />);
      })
      }
    </InfiniteScroll>)
  }, [props.viewModel.list])

  return (<div>{List}</div>);
}

